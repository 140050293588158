<template>
  <div class="mt-4">
    <div class="" >
      <div class="card rounded-xl bg-primary p-4 text-white my-4 font-semibold heading-4">
        {{parseInt(this.$route.params.planId) === 0 ? 'Add Plan' : 'Edit Plan'}}
      </div>
        <div class="lg:grid grid-cols-5 gap-4">
          <div class="col-span-2">
            <div class="card rounded-xl bg-white p-4 my-2">
              <TextField
                :inputId="'plansId'"
                :textMaxlength="100"
                :showcharLimit="false"
                :inputext="sendObj.planName"
                :fieldError="errObj.titleErr !== ''"
                :placholderText="`Plan Title`"
                :lableText="'Plan Title'"
                :autoFocus="false"
                @keyPressAction="errObj.titleErr = ''"
                @inputChangeAction="(data) => sendObj.planName = data"  />
                <p class="text-error heading-7">{{errObj.titleErr}}</p>
                <div class="py-2">
                  <TextField
                    :inputId="'planShortTitle'"
                    :textMaxlength="100"
                    :showcharLimit="false"
                    :inputext="sendObj.shortTitle"
                    :placholderText="`Short Title`"
                    :lableText="'Short Title'"
                    :autoFocus="false"
                    @inputChangeAction="(data) => sendObj.shortTitle = data"  />
                </div>
                <div class="pb-2">
                  <TextField
                    :inputId="'planButTitle'"
                    :textMaxlength="100"
                    :showcharLimit="false"
                    :inputext="sendObj.planButtonTitle"
                    :placholderText="`Plan Button Text`"
                    :lableText="'Plan Button Text'"
                    :autoFocus="false"
                    @inputChangeAction="(data) => sendObj.planButtonTitle = data"  />
                </div>
                <div class="pb-2">
                  <TextField
                    :inputId="'planMaxUser'"
                    :textMaxlength="100"
                    :showcharLimit="false"
                    :inputext="sendObj.planMaxUserTitle"
                    :placholderText="`Max User Title`"
                    :lableText="'Max User Title'"
                    :autoFocus="false"
                    @inputChangeAction="(data) => sendObj.planMaxUserTitle = data"  />
                </div>
                <div class="pb-2">
                  <p class="label_css">Shot Feature List</p>
                  <Editor v-model="sendObj.shortInfo"/>
                </div>
            </div>
            <div class="card rounded-xl bg-white p-4 my-4">
              <div class="flex items-center">
                <label class="cu_switch cursor-pointer mr-3" :class="!sendObj.isFree ? 'ml-2' : ''">
                  <input type="checkbox" v-model="sendObj.isFree" @click="changeButtpn()">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 pl-1">{{sendObj.isFree ? 'This is Free Plan' : 'This is Paid Plan'}}</p>
              </div>
              <div class="w-96 mt-2" v-if="sendObj.isFree">
                <NumberInput
                  :placholderText="`No Of Days`"
                  :lableText="'No Of Days'"
                  :inputId="'cellNumberVal'"
                  :inputext="sendObj.noOfDays"
                  :inputType="'text'"
                  :textRight="false"
                  :textMaxlength="12"
                  :autoFocus="false"
                  @inputChangeAction="(data) => sendObj.noOfDays = data" 
                  @keyPressAction="errObj.phoneErr = ''"
                  :fieldError="errObj.phoneErr !== ''" />
                <p class="text-error heading-7">{{errObj.phoneErr}}</p>
              </div>
            </div>
            <div class="py-4 card rounded-xl bg-white p-2 my-2 mt-0 pt-2">
              <div v-for="(dataA, index) in featuresList" class="p-2 border border-gray2 rounded-lg m-2" :key="index">
                <div class="flex items-center justify-between ">
                  <div class="heading-5 font-semibold" :class="dataA.isSelect ? '' : 'text-text2'"><span v-if="dataA.isSelect" ><i class="fa-solid fa-check text-success  mr-1"></i></span>{{ dataA.displayText }}</div>
                  <div class="flex items-center " >
                    <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary mr-4" @click="checkboxCheck(dataA, index, -1)" v-model="dataA.isSelect" >
                    <TextField class=" w-20"
                      :isSmall="true"
                      :hideLable="true"
                      :placholderText="`Number`"
                      :lableText="'Number'"
                      :inputId="'TEST1'+index+index"
                      :inputext="dataA.textboxValue"
                      :inputType="'number'"
                      :textRight="false"
                      :autoFocus="false"
                      @inputChangeAction="(data) => dataA.textboxValue = data" 
                      />
                  </div>
                </div>
                <div v-for="(childData, indexA) in dataA.childFeatures.list" :key="indexA" v-show="dataA.childFeatures.list !== null" class="flex items-center justify-between pl-2">
                  <div class="heading-5" :class="childData.isSelect ? 'font-semibold' : 'text-text2'"><span v-if="childData.isSelect" ><i class="fa-solid fa-check text-success  mr-1"></i></span>{{ childData.displayText }}</div>
                    <div class="flex items-center py-0.5" >
                      <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary mr-4" @click="checkboxCheck(childData, index, indexA)" v-model="childData.isSelect" >
                      <TextField class=" w-20"
                        :isSmall="true"
                        :hideLable="true"
                        :placholderText="`Number`"
                        :lableText="'Number'"
                        :inputId="'tempvalue12'+indexA+index+indexA+indexA+index"
                        :inputext="childData.textboxValue"
                        :inputType="'number'"
                        :textRight="false"
                        :autoFocus="false"
                        @inputChangeAction="(data) => childData.textboxValue = data" 
                      />
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-span-3" v-if="!sendObj.isFree">
            <div class="card rounded-xl my-4 mt-2">
              <div v-for="(dataA, index) in sendObj.planSubscriptions" :key="index" class="card rounded-xl bg-white p-4 my-2">
                <div class="">
                  <div class="mb-4 sm:mb-0 w-96 gap-4">
                    <Dropdown
                      :inputext="dataA.currencyName" 
                      :inputId="'currencyData'+index"
                      :lableText="'Currency'"
                      :placholderText="`Currency`"
                      :autoFocus="false"
                      :showArrow="true"
                      :setReadOnly="false"
                      :searchCallApi="true"
                      :fieldError="dataA.currErr !== ''"
                      :showPlus="false"
                      :keyName="'displayText'"
                      :listId="'currencyMasterId'"
                      :items="dataA.currenctList"
                      @enterkeyPressAction="dataA.currErr =''"
                      @focus="currentIndexOfWorkExp = index"
                      @selectNewForVal="selectNewCustomer"
                      />
                    <p v-if="dataA.currErr !== ''" class="text-error heading-7">{{dataA.currErr}}</p>
                </div>
                <div v-for="(subData, subindex) in dataA.paymentOption" :key="subindex">
                  <div class="pt-2">
                    <div class="heading-5 text-text2 font-semibold">{{ subData.paymentType === 'm' ? 'Monthly:' : 'Yearly:' }}</div>
                  </div>
                  <div class="grid grid-cols-3 gap-4 pl-2">
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'payTitle'+subindex+index"
                        :inputext="subData.paymentTitle"
                        :fieldError="subData.titleErr !== ''"
                        :placholderText="`Payment Title`"
                        :lableText="'Payment Title'"
                        :autoFocus="false"
                        @keyPressAction="subData.titleErr = ''"
                        @inputChangeAction="(data) => subData.paymentTitle = data"  />
                        <p class="text-error heading-7">{{subData.titleErr}}</p>
                    </div>
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'payid'+subindex+index"
                        :inputext="subData.paymentId"
                        :fieldError="subData.payIdErr !== ''"
                        :placholderText="`Payment Id`"
                        :lableText="'Payment Id'"
                        :autoFocus="false"
                        @keyPressAction="subData.payIdErr = ''"
                        @inputChangeAction="(data) => subData.paymentId = data"  />
                        <p class="text-error heading-7">{{subData.payIdErr}}</p>
                    </div>
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'payUrl'+subindex+index"
                        :inputext="subData.paymentLink"
                        :fieldError="subData.payLinkErr !== ''"
                        :placholderText="`Payment Link`"
                        :lableText="'Payment Link'"
                        :autoFocus="false"
                        @keyPressAction="subData.payLinkErr = ''"
                        @inputChangeAction="(data) => subData.paymentLink = data"  />
                        <p class="text-error heading-7">{{subData.payLinkErr}}</p>
                    </div>
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'saveAmtText'+subindex+index+index"
                        :inputext="subData.saveAmountText"
                        :fieldError="subData.saveAmtErr !== ''"
                        :placholderText="`Save Amount Text`"
                        :lableText="'Save Amount Text'"
                        :autoFocus="false"
                        @keyPressAction="subData.saveAmtErr = ''"
                        @inputChangeAction="(data) => subData.saveAmountText = data"  />
                        <p class="text-error heading-7">{{subData.saveAmtErr}}</p>
                    </div>
                  </div>
                  <div class="flex items-center p-2">
                    <label class="cu_switch cursor-pointer mr-3" :class="!subData.isActive ? 'ml-2' : ''">
                      <input type="checkbox" v-model="subData.isActive">
                      <span class="cu_slider cu_round"></span>
                    </label>
                    <p class="text-gray3 pl-1">{{subData.isActive ? 'Active' : 'InActive'}}</p>
                  </div>
                </div>
                <div>
                  <div class="flex justify-end">
                    <span class=" rounded-full h-8 w-8 bg-error mr-1 cursor-pointer flex items-center text-center justify-center" v-if="sendObj.planSubscriptions.length > 1" @click="removeWorkDetails(index)">
                      <i class="fas fa-minus text-white heading-3"></i>
                    </span>
                    <div class=" rounded-full h-8 w-8 bg-primary cursor-pointer flex items-center text-center justify-center" v-if="index === sendObj.planSubscriptions.length - 1" @click="addWorkDetails(index)">
                      <i class="fas fa-plus text-white heading-3"></i>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end card rounded-xl bg-white p-4 sticky bottom-0">
          <div class="text-rigth flex gap-2">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="crateJob()"/>
          </div>
        </div>
    </div>
    <div>
    </div>
   </div>
</template>
<script>
import NumberInput from '@/View/components/number-text-field.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Editor from '../../../components/editor.vue'
export default {
  name: "customer",
  components: {
    Button,
    TextField,
    NumberInput,
    Dropdown,
    Editor,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      planDetailObj: null,
      currentIndexOfWorkExp: 0,
      featuresList: [],
      currentyList: [],
      sendObj: {
        shortTitle: '',
        planButtonTitle: '',
        planMaxUserTitle: '',
        shortInfo: '',
        planName: '',
        noOfDays: 30,
        isFree: true,
        planId: 0,
        isActive: true,
        planFeatures: [],
        planSubscriptions: [
          {
            currencyMasterId: 0,
            currencyName: '',
            currenctList: [],
            currErr: '',
            paymentOption : [
              {
                titleErr: '',
                payIdErr: '',
                payLinkErr: '',
                saveAmtErr: '',
                planSubscriptionDetailId: 0,
                paymentType: 'm',
                paymentTitle: '',
                saveAmountText: '',
                paymentId: '',
                paymentLink: '',
                isActive: true
              },
              {
                titleErr: '',
                payIdErr: '',
                payLinkErr: '',
                saveAmtErr: '',
                planSubscriptionDetailId: 0,
                paymentType: 'y',
                paymentTitle: '',
                saveAmountText: '',
                paymentId: '',
                paymentLink: '',
                isActive: true
              }
            ]
          },
        ],
      },
      errObj: {
        custErr:'',
        phoneErr: '',
        titleErr:'',
        addressErr:''
      },
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    featuresList: {
      handler () {
        console.log('featuresList', this.featuresList)
        for (let II = 0; II < this.featuresList.length; II++) {
            // if (this.featuresList[II].textboxValue !== '0') {
            //   this.featuresList[II].isSelect = true
            // }
            // } else if (this.featuresList[II].textboxValue === '') {
            //   this.featuresList[II].isSelect = false
            //   this.featuresList[II].textboxValue = '0'
            // }
            if (this.featuresList[II].childFeatures.list !== null) {
              for (let JJ = 0; JJ < this.featuresList[II].childFeatures.list.length; JJ++) {
                // if (this.featuresList[II].childFeatures.list[JJ].textboxValue !== '0') {
                //   this.featuresList[II].childFeatures.list[JJ].isSelect = true
                // }
                // } else if (this.featuresList[II].childFeatures.list[JJ].textboxValue === '') {
                //   this.featuresList[II].childFeatures.list[JJ].isSelect = false
                //   this.featuresList[II].childFeatures.list[JJ].textboxValue = '0'
                // }
              }
            }
          }
      },
      deep: true
    }
  },
  mounted() {
    if (parseInt(this.$route.params.planId) > 0) {
      this.getPlanDetailA(parseInt(this.$route.params.planId))
    }
    console.log("route", this.$route.query);
    this.getFeaturesList()
    this.getCurrentyList()
  },
  beforeDestroy() {
  },
  methods: {
    changeButtpn () {
      this.sendObj.isFree = !this.sendObj.isFree
      console.log('data, datadatadatadata', this.sendObj.isFree)
      this.sendObj.planSubscriptions = []
      if (this.sendObj.isFree) {
        this.sendObj.planSubscriptions = []
      } else {
        this.sendObj.planSubscriptions.push({
        currencyMasterId: 0,
        currencyName: '',
        currenctList: this.currentyList,
        currErr: '',
        paymentOption: [
          {
            titleErr: '',
            payIdErr: '',
            payLinkErr: '',
            saveAmtErr: '',
            planSubscriptionDetailId: 0,
            paymentType: 'm',
            paymentTitle: '',
            saveAmountText: '',
            paymentId: '',
            paymentLink: '',
            isActive: true
          },
          {
            titleErr: '',
            payIdErr: '',
            payLinkErr: '',
            saveAmtErr: '',
            planSubscriptionDetailId: 0,
            paymentType: 'y',
            paymentTitle: '',
            saveAmountText: '',
            paymentId: '',
            paymentLink: '',
            isActive: true
          }
        ]
      })
      }
    },
    sadsadsadnsajkdsankd (data) {
      if (data.textboxValue === '') {
        data.textboxValue = '0'
      }
    },
    checkboxCheck (data, index, subindex) {
      if (subindex === -1) {
        this.featuresList[index].isSelect = ! this.featuresList[index].isSelect
        if (!this.featuresList[index].isSelect) {
          this.featuresList[index].textboxValue = '0'
        }
      } else {
        this.featuresList[index].childFeatures.list[subindex].isSelect = !this.featuresList[index].childFeatures.list[subindex].isSelect
        if (!this.featuresList[index].childFeatures.list[subindex].isSelect) {
          this.featuresList[index].childFeatures.list[subindex].textboxValue = '0'
        }
      }
      // console.log('data, datadatadatadata', data)
      // if (data.isSelect === true) {
      //   // data.textboxValue = '0'
      //   data.isSelect = false
      // } else {
      //   // data.textboxValue = '1'
      //   data.isSelect = true
      // }
    },
    selectNewCustomer (data) {
      console.log('312321312313', data)
      if (data !== null) {
        this.sendObj.planSubscriptions[this.currentIndexOfWorkExp].currencyName = data.displayText
        this.sendObj.planSubscriptions[this.currentIndexOfWorkExp].currencyMasterId = data.currencyMasterId
      } else {
        this.sendObj.planSubscriptions[this.currentIndexOfWorkExp].currencyName = ''
      }
      this.sendObj.planSubscriptions[this.currentIndexOfWorkExp].currErr = ''
    },
    removeWorkDetails (index) {
      if (this.sendObj.planSubscriptions.length !== 1) {
        this.sendObj.planSubscriptions.splice(index, 1)
        // this.ErrObj.splice(index, 1)
      }
    },
    crateJob () {
      let isValid = true
      if (this.sendObj.planName === '') {
        this.errObj.titleErr = 'Plan Title is Required'
        isValid = false
      }
      if (this.sendObj.isFree) {
        if (this.sendObj.noOfDays === '' || this.sendObj.noOfDays === '0' || this.sendObj.noOfDays === 0) {
          this.errObj.phoneErr = 'No of days is should be grether than 0'
          isValid = false
        }
      }
      if (!this.sendObj.isFree) {
        for (let II = 0; II < this.sendObj.planSubscriptions.length; II++) {
          let element = this.sendObj.planSubscriptions[II]
          console.log('elementelement', element)
          if (element.currencyMasterId === 0) {
            element.currErr = 'Currency is Required'
            isValid = false
          }
          for (let JJ = 0; JJ < element.paymentOption.length; JJ++) {
            if (element.paymentOption[JJ].paymentType === 'm') {
              if (element.paymentOption[JJ].paymentTitle === '') {
                element.paymentOption[JJ].titleErr = 'Payment Title is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].paymentId === '') {
                element.paymentOption[JJ].payIdErr = 'Payment Id is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].paymentLink === '') {
                element.paymentOption[JJ].payLinkErr = 'Payment Link is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].saveAmountText === '') {
                element.paymentOption[JJ].saveAmtErr = 'Save Amount Text is Required'
                isValid = false
              }
            } else {
              if (element.paymentOption[JJ].paymentTitle === '') {
                element.paymentOption[JJ].titleErr = 'Payment Title is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].paymentId === '') {
                element.paymentOption[JJ].payIdErr = 'Payment Id is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].paymentLink === '') {
                element.paymentOption[JJ].payLinkErr = 'Payment Link is Required'
                isValid = false
              }
              if (element.paymentOption[JJ].saveAmountText === '') {
                element.paymentOption[JJ].saveAmtErr = 'Save Amount Text is Required'
                isValid = false
              }
            }
          }
        }
      }
      if (isValid) {
        if (this.sendObj.isFree === false) {
          this.sendObj.noOfDays = 0
        }
        this.sendObj.noOfDays = parseInt(this.sendObj.noOfDays)
        this.sendObj.planFeatures = []
        console.log('this.sendObj234', this.featuresList)
        for (let II = 0; II < this.featuresList.length; II++) {
          if (this.featuresList[II].isSelect) {
            this.sendObj.planFeatures.push({
              name: this.featuresList[II].displayText,
              appFeaturesId: this.featuresList[II].appFeaturesId,
              isActive: this.featuresList[II].isActive,
              numberVal: parseInt(this.featuresList[II].textboxValue),
            })
          }
          if (this.featuresList[II].childFeatures.list !== null) {
            for (let JJ = 0; JJ < this.featuresList[II].childFeatures.list.length; JJ++) {
              console.log('this.featuresList[II].isSelect', this.featuresList[II])
              if (this.featuresList[II].childFeatures.list[JJ].isSelect) {
                this.sendObj.planFeatures.push({
                  name: this.featuresList[II].childFeatures.list[JJ].displayText,
                  appFeaturesId: this.featuresList[II].childFeatures.list[JJ].appFeaturesId,
                  isActive: this.featuresList[II].childFeatures.list[JJ].isActive,
                  numberVal: parseInt(this.featuresList[II].childFeatures.list[JJ].textboxValue),
                })
              }
            }
          }
        }
        console.log('this.sendObj', this.sendObj)
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.SavePlanApi(
          this.sendObj,
          response => {
            this.$router.push({name: 'PlanDetail', params: {planId: response.Data}})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    addWorkDetails (i) {
      console.log(i)
      this.sendObj.planSubscriptions.push({
        currencyMasterId: 0,
        currencyName: '',
        currenctList: this.currentyList,
        currErr: '',
        paymentOption: [
          {
            titleErr: '',
            payIdErr: '',
            payLinkErr: '',
            saveAmtErr: '',
            planSubscriptionDetailId: 0,
            paymentType: 'm',
            paymentTitle: '',
            saveAmountText: '',
            paymentId: '',
            paymentLink: '',
            isActive: true
          },
          {
            titleErr: '',
            payIdErr: '',
            payLinkErr: '',
            saveAmtErr: '',
            planSubscriptionDetailId: 0,
            paymentType: 'y',
            paymentTitle: '',
            paymentId: '',
            saveAmountText: '',
            paymentLink: '',
            isActive: true
          }
        ]
      })
      // this.ErrObj.push({
      //   dateValidErr: false,
      //   CompanyContactError: false,
      //   emailFormatError: false
      // })
    },
    getFeaturesList () {
      console.log('this.routing', this.orgId)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetFeaturesList(
        true,
        response => {
          let temp = response.Data === null ? [] : response.Data
          for (let II = 0; II < temp.length; II++) {
            temp[II].textboxValue = '0'
            if (temp[II].childFeatures.list !== null) {
              for (let JJ = 0; JJ < temp[II].childFeatures.list.length; JJ++) {
                temp[II].childFeatures.list[JJ].textboxValue = '0'
              }
            }
          }
          this.featuresList = temp
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getCurrentyList () {
      console.log('this.routing', this.orgId)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetCurrentyList(
        true,
        response => {
          this.currentyList = response.Data === null ? [] : response.Data
          for (let index = 0; index < this.sendObj.planSubscriptions.length; index++) {
            this.sendObj.planSubscriptions[index].currenctList = this.currentyList
          }
          console.log('this.sendObjsadd.', this.sendObj.planSubscriptions)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getPlanDetailA (data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetPlanDetail(
        data,
        response => {
          this.planDetailObj = response.Data === null ? [] : response.Data
          this.sendObj.planName = this.planDetailObj.planName
          this.sendObj.shortInfo = this.planDetailObj.shortInfo
          this.sendObj.shortTitle = this.planDetailObj.shortTitle
          this.sendObj.planButtonTitle = this.planDetailObj.planButtonTitle
          this.sendObj.planMaxUserTitle = this.planDetailObj.planMaxUserTitle
          this.sendObj.noOfDays = parseInt(this.planDetailObj.noOfDays)
          this.sendObj.isFree = this.planDetailObj.isFree
          this.sendObj.planId = this.planDetailObj.planId
          this.sendObj.isActive = this.planDetailObj.isActive
          this.sendObj.planFeatures = this.planDetailObj.planFeatures
          setTimeout(() => {
            if (parseInt(this.$route.params.planId) > 0 && this.sendObj.planFeatures.length > 0) {
              for (let KK = 0; KK < this.sendObj.planFeatures.length; KK++) {
                for (let II = 0; II < this.featuresList.length; II++) {
                  if (this.sendObj.planFeatures[KK].appFeaturesId === this.featuresList[II].appFeaturesId) {
                    this.featuresList[II].isSelect = true
                    this.featuresList[II].textboxValue = this.sendObj.planFeatures[KK].numberVal
                  }
                  if (this.featuresList[II].childFeatures.list !== null) {
                    for (let JJ = 0; JJ < this.featuresList[II].childFeatures.list.length; JJ++) {
                      if (this.sendObj.planFeatures[KK].appFeaturesId === this.featuresList[II].childFeatures.list[JJ].appFeaturesId) {
                        this.featuresList[II].childFeatures.list[JJ].isSelect = true
                        this.featuresList[II].childFeatures.list[JJ].textboxValue = this.sendObj.planFeatures[KK].numberVal
                      }
                    }
                  }
                }
              }
            }
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }, 500);
          console.log('this.featuresList', this.featuresList)
          console.log('this.planDetailObj.planSubscriptions', this.planDetailObj.planSubscriptions)
          this.sendObj.planSubscriptions = []
          if (this.planDetailObj.planSubscriptions !== null) {
            for (let index = 0; index < this.planDetailObj.planSubscriptions.length; index++) {
              let Obj = {
                currencyMasterId: this.planDetailObj.planSubscriptions[index].currencyMasterId,
                currencyName: this.planDetailObj.planSubscriptions[index].countryTitle,
                currenctList: this.currentyList,
                currErr: '',
                paymentOption : []
              }
              for (let JJ = 0; JJ < this.planDetailObj.planSubscriptions[index].paymentOption.paymentOption.length; JJ++) {
                const element = this.planDetailObj.planSubscriptions[index].paymentOption.paymentOption[JJ]
                let Object = {
                  titleErr: '',
                  payIdErr: '',
                  payLinkErr: '',
                  saveAmtErr: '',
                  planSubscriptionDetailId: element.planSubscriptionDetailId,
                  paymentType: element.paymentType,
                  paymentTitle: element.paymentTitle,
                  saveAmountText: element.saveAmountText,
                  paymentId: element.paymentId,
                  paymentLink: element.paymentLink,
                  isActive: true
                }
                Obj.paymentOption.push(Object)
              }
              this.sendObj.planSubscriptions.push(Obj)
            }
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
  },
};
</script>
<style scoped>

</style>