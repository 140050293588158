<template>
    <div v-click-outside-textInput="closePopup" class="relative">
          <p class="label_css flex justify-between" v-if="!hideLable">
            <span>{{lableText}}</span>
            <span class="text contents" v-if="showcharLimit">{{inputValue.length}}/{{textMaxlength}}</span>
          </p>
            <input
            :maxlength="textMaxlength"
            @keypress="keyPressAction(inputValue)"
            @keypress.enter="enterkeyPressAction"  
            @blur="blurAction"
            @input="inputChangeAction(inputValue)"
            @change="change"
            @keyup="keyup"
            :pattern="pattern"
            @click.stop.prevent="onClickInputAction"
            @focus="focus"
            v-model="inputValue"
            :disabled="disabled"
            :placeholder="placholderText"
            :readonly="setReadOnly"
            :type="inputTypeText"
            :style="`${isOtp ? 'letter-spacing: 8px;text-align: center;' : ''}`"
            :class="`${isSmall ? 'py-1' : 'py-3'} ${inputType === 'color' ? 'h-12 py-0' : ''} ${isPointer === true ? 'cursor-pointer' : ''} ${textRight ? 'text-right pr-2' : ''} ${showError ? 'border border-error text-error' : 'border border-gray2 flex text-text1 focus:border-primary focus:ring-1 focus:ring-primary'}
            pr-${inputType === 'password' || showDate ? '8' : '0'}
            `"
            :autofocus="setAutoFocust"
            autocomplete="off"
            :id="inputId"
            class="cust_text_field  px-2 w-full bg-white rounded items-center">


                <span v-if="inputType === 'password'" @click.stop.prevent="hidePassword = !hidePassword" 
                  :class="lableText !== '' ? 'top-8' : 'top-3' "
                  class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                    <p v-if="!hidePassword">
                      <i class="fa-solid fa-eye-slash text-gray3 heading-6"></i>
                    </p>
                  <span v-if="hidePassword">
                    <i class="fa-solid fa-eye text-gray3 heading-6"></i>
                  </span>
                </span>
                <span @click="onClickInputAction" v-if="showDate" 
                  :class="lableText !== '' ? 'top-8' : 'top-3' "
                  class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                  
                   <i class="fa-solid fa-calendar-days text-gray3 heading-6"></i>
                </span>
                <span @click="onClickInputAction" v-if="showTime" 
                :class="lableText !== '' ? 'top-8' : 'top-3' "
                class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                   <i class="fa-regular fa-clock heading-3 text-primary"></i>
                </span>

        </div>
</template>
<script>
import Vue from 'vue'
Vue.directive('click-outside-textInput', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
    props: ['hideLable', 'isSmall',  'pattern', 'isOtp', 'isPointer', 'showcharLimit' ,"textRight", "inputext", "textMaxlength", 'lableText', 'inputId', 'autoFocus', 'fieldError', 'setReadOnly', 'placholderText', 'inputType', "showDate", "disabled", "showTime"],
  data() {
    return {
      inputTypeText: 'text',
      hidePassword: true,
      showError: false,
      setAutoFocust: false,
      disabledBtn: false,
      inputValue: ''
    };
  },
  watch: {
    fieldError: {
        handler () {
          console.log('fieldError', this.fieldError);
          this.showError = this.fieldError
        }
    },
    hidePassword: {
        handler () {
          if (this.hidePassword) {
            this.inputTypeText = 'password'
            document.getElementById(`${this.inputId}`).focus()
          } else {
            this.inputTypeText = 'text'
            document.getElementById(`${this.inputId}`).focus()
          }
        }
    },
    inputext: {
        handler () {
            if (this.inputext) {
                this.inputValue = this.inputext
            } else {
              this.inputValue = this.inputext
            }
        }
    }
  },
  mounted() {
    this.showError = this.fieldError
    this.inputTypeText = this.inputType
    if (this.disabled) {
      this.disabledBtn = this.disabled
    }
    setTimeout(() => {
      this.inputValue = this.inputext
     }, 200);
    setTimeout(() => {
      if (this.autoFocus) {
        document.getElementById(`${this.inputId}`).focus()
      }
    }, 10);
  },
  methods: {
    inputChangeAction (text) {
        this.$emit('inputChangeAction', text)
    },
    change () {
        this.$emit('change')
    },
    keyPressAction (text) {
        this.$emit('keyPressAction', text)
    },
    enterkeyPressAction () {
        this.$emit('enterkeyPressAction')
    },
    blurAction () {
        this.$emit('blurAction')
    },
    focus () {
        this.$emit('focus')
    },
    onClickInputAction () {
      document.getElementById(`${this.inputId}`).focus()
        this.$emit('onClickInputAction')
    },
    closePopup () {
        this.$emit('closePopup')
    },
    keyup () {
        this.$emit('keyup')
    }
  },
};
</script>
<style scoped>
</style>
