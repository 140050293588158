var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside-textInput",rawName:"v-click-outside-textInput",value:(_vm.closePopup),expression:"closePopup"}],staticClass:"relative"},[(!_vm.hideLable)?_c('p',{staticClass:"label_css flex justify-between"},[_c('span',[_vm._v(_vm._s(_vm.lableText))]),(_vm.showcharLimit)?_c('span',{staticClass:"text contents"},[_vm._v(_vm._s(_vm.inputValue.length)+"/"+_vm._s(_vm.textMaxlength))]):_vm._e()]):_vm._e(),((_vm.inputTypeText)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"cust_text_field px-2 w-full bg-white rounded items-center",class:`${_vm.isSmall ? 'py-1' : 'py-3'} ${_vm.inputType === 'color' ? 'h-12 py-0' : ''} ${_vm.isPointer === true ? 'cursor-pointer' : ''} ${_vm.textRight ? 'text-right pr-2' : ''} ${_vm.showError ? 'border border-error text-error' : 'border border-gray2 flex text-text1 focus:border-primary focus:ring-1 focus:ring-primary'}
        pr-${_vm.inputType === 'password' || _vm.showDate ? '8' : '0'}
        `,style:(`${_vm.isOtp ? 'letter-spacing: 8px;text-align: center;' : ''}`),attrs:{"maxlength":_vm.textMaxlength,"pattern":_vm.pattern,"disabled":_vm.disabled,"placeholder":_vm.placholderText,"readonly":_vm.setReadOnly,"autofocus":_vm.setAutoFocust,"autocomplete":"off","id":_vm.inputId,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"keypress":[function($event){return _vm.keyPressAction(_vm.inputValue)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterkeyPressAction.apply(null, arguments)}],"blur":_vm.blurAction,"input":function($event){return _vm.inputChangeAction(_vm.inputValue)},"change":[function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}},_vm.change],"keyup":_vm.keyup,"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickInputAction.apply(null, arguments)},"focus":_vm.focus}}):((_vm.inputTypeText)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"cust_text_field px-2 w-full bg-white rounded items-center",class:`${_vm.isSmall ? 'py-1' : 'py-3'} ${_vm.inputType === 'color' ? 'h-12 py-0' : ''} ${_vm.isPointer === true ? 'cursor-pointer' : ''} ${_vm.textRight ? 'text-right pr-2' : ''} ${_vm.showError ? 'border border-error text-error' : 'border border-gray2 flex text-text1 focus:border-primary focus:ring-1 focus:ring-primary'}
        pr-${_vm.inputType === 'password' || _vm.showDate ? '8' : '0'}
        `,style:(`${_vm.isOtp ? 'letter-spacing: 8px;text-align: center;' : ''}`),attrs:{"maxlength":_vm.textMaxlength,"pattern":_vm.pattern,"disabled":_vm.disabled,"placeholder":_vm.placholderText,"readonly":_vm.setReadOnly,"autofocus":_vm.setAutoFocust,"autocomplete":"off","id":_vm.inputId,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"keypress":[function($event){return _vm.keyPressAction(_vm.inputValue)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterkeyPressAction.apply(null, arguments)}],"blur":_vm.blurAction,"input":function($event){return _vm.inputChangeAction(_vm.inputValue)},"change":[function($event){_vm.inputValue=null},_vm.change],"keyup":_vm.keyup,"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickInputAction.apply(null, arguments)},"focus":_vm.focus}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"cust_text_field px-2 w-full bg-white rounded items-center",class:`${_vm.isSmall ? 'py-1' : 'py-3'} ${_vm.inputType === 'color' ? 'h-12 py-0' : ''} ${_vm.isPointer === true ? 'cursor-pointer' : ''} ${_vm.textRight ? 'text-right pr-2' : ''} ${_vm.showError ? 'border border-error text-error' : 'border border-gray2 flex text-text1 focus:border-primary focus:ring-1 focus:ring-primary'}
        pr-${_vm.inputType === 'password' || _vm.showDate ? '8' : '0'}
        `,style:(`${_vm.isOtp ? 'letter-spacing: 8px;text-align: center;' : ''}`),attrs:{"maxlength":_vm.textMaxlength,"pattern":_vm.pattern,"disabled":_vm.disabled,"placeholder":_vm.placholderText,"readonly":_vm.setReadOnly,"autofocus":_vm.setAutoFocust,"autocomplete":"off","id":_vm.inputId,"type":_vm.inputTypeText},domProps:{"value":(_vm.inputValue)},on:{"keypress":[function($event){return _vm.keyPressAction(_vm.inputValue)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterkeyPressAction.apply(null, arguments)}],"blur":_vm.blurAction,"input":[function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value},function($event){return _vm.inputChangeAction(_vm.inputValue)}],"change":_vm.change,"keyup":_vm.keyup,"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickInputAction.apply(null, arguments)},"focus":_vm.focus}}),(_vm.inputType === 'password')?_c('span',{staticClass:"place-self-center absolute right-3 cursor-pointer mt-0.5",class:_vm.lableText !== '' ? 'top-8' : 'top-3',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.hidePassword = !_vm.hidePassword}}},[(!_vm.hidePassword)?_c('p',[_c('i',{staticClass:"fa-solid fa-eye-slash text-gray3 heading-6"})]):_vm._e(),(_vm.hidePassword)?_c('span',[_c('i',{staticClass:"fa-solid fa-eye text-gray3 heading-6"})]):_vm._e()]):_vm._e(),(_vm.showDate)?_c('span',{staticClass:"place-self-center absolute right-3 cursor-pointer mt-0.5",class:_vm.lableText !== '' ? 'top-8' : 'top-3',on:{"click":_vm.onClickInputAction}},[_c('i',{staticClass:"fa-solid fa-calendar-days text-gray3 heading-6"})]):_vm._e(),(_vm.showTime)?_c('span',{staticClass:"place-self-center absolute right-3 cursor-pointer mt-0.5",class:_vm.lableText !== '' ? 'top-8' : 'top-3',on:{"click":_vm.onClickInputAction}},[_c('i',{staticClass:"fa-regular fa-clock heading-3 text-primary"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }